.emailbox {
    position: fixed; 
    width: 45%; 
    height: 100vh; 

    padding-left: 50px;
    padding-top: 75px;
}

.input-field {
    border: 3px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to right, black, blue900);
    padding: 10px;
    width: 100%;
    border-radius: 5px;
}
.text-field {
    border: 3px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to right, black, blue900);
    padding: 10px;
    width: 100%;
    height: 200px;
    border-radius: 5px;
}