.popup {
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100vh; 

    display: flex; 
    justify-content: center; 
    align-items: center; 
}